import { GALLERY_CONSTS } from 'pro-gallery';
import { FULLSCREEN_EVENTS } from '@wix/pro-fullscreen-renderer';
import { utils } from '../../utils/webUtils';

export default class AsyncEventHandler {
  constructor(galleryWrapper, props) {
    this.galleryWrapper = galleryWrapper;
    this.galleryWrapperProps = props;

    this.update = this.update.bind(this);
    this.handleEvent = this.handleEvent.bind(this);
    this.handleFullscreenEvent = this.handleFullscreenEvent.bind(this);
  }

  update(props) {
    this.galleryWrapperProps = props;
  }

  async handleEvent(eventName, eventData, event) {
    switch (eventName) {
      case GALLERY_CONSTS.events.LOAD_MORE_CLICKED:
        this.galleryWrapper.loadMoreClicked = true;
        break;
      case GALLERY_CONSTS.events.ITEM_ACTION_TRIGGERED:
        this.galleryWrapper.itemActionsHelper.onItemActionTriggered(
          eventData,
          this.galleryWrapper.siteHelper.getPGStyles(),
        );
        break;
      case GALLERY_CONSTS.events.CURRENT_ITEM_CHANGED:
        this.galleryWrapper.itemActionsHelper.onCurrentItemChanged(eventData);
        break;

      case GALLERY_CONSTS.events.NEED_MORE_ITEMS:
        this.galleryWrapper.itemsHelper.getMoreItems(eventData);
        break;
      case GALLERY_CONSTS.events.SHARE_BUTTON_CLICKED:
        await this.galleryWrapper.itemActionsHelper.handleItemActions(
          'share',
          eventData,
        );
        this.galleryWrapper.logHelper.reportBiEvent(
          'share',
          eventData,
          'gallery',
        );
        break;
      case GALLERY_CONSTS.events.TEXT_DOWNLOAD_BUTTON_CLICKED:
        await this.galleryWrapper.itemActionsHelper.handleItemActions(
          'downloadTextItem',
          eventData,
        );
        this.galleryWrapper.logHelper.reportBiEvent(
          'downloadTextItem',
          eventData,
          'gallery',
        );
        break;
      case GALLERY_CONSTS.events.LOVE_BUTTON_CLICKED:
        this.galleryWrapper.itemActionsHelper.updateLocalLoveData(eventData.id);
        await this.galleryWrapper.itemActionsHelper.handleItemActions(
          'postLoveActivity',
          eventData,
        );
        if (
          !(await this.galleryWrapper.itemActionsHelper.useItemActions(
            'isLoved',
            eventData.id,
          ))
        ) {
          // check isLoved before toggleLove action
          // report bi event only if loved, not if unloved
          this.galleryWrapper.logHelper.reportBiEvent(
            'love',
            eventData,
            'gallery',
          );
        }
        await this.galleryWrapper.itemActionsHelper.handleItemActions(
          'toggleLove',
          eventData,
        );
        break;
      case GALLERY_CONSTS.events.ITEM_CLICKED:
        this.galleryWrapper.itemActionsHelper.onItemClicked(eventData, event);
        this.galleryWrapper.logHelper.reportBiEvent(
          'onItemClicked',
          eventData,
          'gallery',
        );
        break;
      case GALLERY_CONSTS.events.DOWNLOAD_BUTTON_CLICKED:
        this.galleryWrapper.logHelper.reportBiEvent(
          'onDownloadButtonClicked',
          eventData,
          'gallery',
        );
        break;
      case GALLERY_CONSTS.events.BUY_NOW_CLICKED:
        this.galleryWrapper.logHelper.reportBiEvent(
          'onBuyNowClicked',
          eventData,
          'gallery',
        );
        break;
      case GALLERY_CONSTS.events.THUMBNAIL_CLICKED:
        this.galleryWrapper.logHelper.reportBiEvent(
          'onThumbnailClicked',
          eventData,
          'gallery',
        );
        break;
      default:
        if (utils.isVerbose()) {
          console.log(eventName, 'is not handled in handleEvent function');
        }
    }
  }

  async handleFullscreenEvent(eventName, eventData) {
    switch (eventName) {
      case FULLSCREEN_EVENTS.UPDATE_CURRENT_ITEM:
        await this.galleryWrapper.fullscreenHelper.updateFullscreenCurrentItem(
          eventData.item,
        );
        break;
      case FULLSCREEN_EVENTS.CLOSE_FULLSCREEN:
        this.galleryWrapper.fullscreenHelper.animatedCloseFullscreen(
          eventData.itemIdx,
          eventData.animationDuration,
        );
        break;
      case FULLSCREEN_EVENTS.NEED_MORE_ITEMS:
        this.galleryWrapper.itemsHelper.getMoreItems(eventData);
        break;
      case FULLSCREEN_EVENTS.TOGGLE_BROWSER_FULLSCREEN:
        this.galleryWrapper.fullscreenHelper.toggleBrowserFullscreen();
        break;
      case FULLSCREEN_EVENTS.NAVIGATE:
        this.galleryWrapper.itemActionsHelper.onLinkNavigation(eventData);
        break;
      case FULLSCREEN_EVENTS.FULLSCREEN_LOADED:
        this.galleryWrapper.logHelper.reportBiEvent(
          'item_expanded',
          eventData,
          'fullscreen',
        );
        break;
      case FULLSCREEN_EVENTS.SHARE_BUTTON_CLICKED:
        await this.galleryWrapper.itemActionsHelper.handleItemActions(
          'share',
          eventData,
        );
        this.galleryWrapper.logHelper.reportBiEvent(
          'share',
          eventData,
          'fullscreen',
        );
        break;
      case FULLSCREEN_EVENTS.TEXT_DOWNLOAD_BUTTON_CLICKED:
        await this.galleryWrapper.itemActionsHelper.handleItemActions(
          'downloadTextItem',
          eventData,
        );
        this.galleryWrapper.logHelper.reportBiEvent(
          'downloadTextItem',
          eventData,
          'fullscreen',
        );
        break;
      case FULLSCREEN_EVENTS.DOWNLOAD_BUTTON_CLICKED:
        this.galleryWrapper.logHelper.reportBiEvent(
          'onDownloadButtonClicked',
          eventData,
          'fullscreen',
        );
        break;
      case FULLSCREEN_EVENTS.LOVE_BUTTON_CLICKED:
        this.galleryWrapper.itemActionsHelper.updateLocalLoveData(eventData.id);
        await this.galleryWrapper.itemActionsHelper.handleItemActions(
          'postLoveActivity',
          eventData,
        );
        if (
          !(await this.galleryWrapper.itemActionsHelper.useItemActions(
            'isLoved',
            eventData.id,
          ))
        ) {
          // check isLoved before toggleLove action
          // report bi event only if loved, not if unloved
          this.galleryWrapper.logHelper.reportBiEvent(
            'love',
            eventData,
            'fullscreen',
          );
        }
        await this.galleryWrapper.itemActionsHelper.handleItemActions(
          'toggleLove',
          eventData,
        );
        break;
      default:
        if (utils.isVerbose()) {
          console.log(eventName, 'is not handled in handleEvent function');
        }
    }
  }
}
